import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { SectionTitle } from "../components/SectionTitle"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { StickyImage } from "../components/stickyImage"
import { OrangeLink } from "../components/buttons"
import { Body, MainComponent, SideImg, TBListComponent, TBWBody } from "../components/about"

const Volunteers = () => {
    const pageData = useStaticQuery(graphql`
        query {
            contentfulSectionVolunteers {
                title
            }
            contentfulSectionVolunteersBodyRichTextNode {
                json
            }
            contentfulTbwVp {
                title
            }
            contentfulTbwVpBodyRichTextNode {
                json
            }
            contentfulFooterLogo {
                logo {
                    file {
                        url
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <StickyImage />
            <MainComponent>
                <SectionTitle title={pageData.contentfulSectionVolunteers.title} />
                <Body>
                    <SideImg src={pageData.contentfulFooterLogo.logo.file.url} alt="side image" />
                    <div>{documentToReactComponents(pageData.contentfulSectionVolunteersBodyRichTextNode.json)}</div>
                </Body>
                <OrangeLink to="/app/signup">S'INSCRIRE</OrangeLink>
            </MainComponent>
            <TBListComponent color="#f57b24">
                <h5>{pageData.contentfulTbwVp.title}</h5>
                <TBWBody>{documentToReactComponents(pageData.contentfulTbwVpBodyRichTextNode.json)}</TBWBody>
            </TBListComponent>
            <FindMissionComponent>
                <p>
                    Tu es convaincu ? <br />
                    Tu veux devenir bénévole ?
                </p>
                <OrangeLink to="/app/search">RECHERCHER UNE MISSION</OrangeLink>
            </FindMissionComponent>
        </Layout>
    )
}

export default Volunteers

const FindMissionComponent = styled.div`
    text-align: center;
    margin: 2rem 0;
    & > p {
        font-weight: bold;
    }
`
